
import {defineComponent} from "vue";

export default defineComponent({
  name: "kt-account",
  components: {
  },
  methods: {
    alpSelected (value){
      this.$emit('alpSelected', value);
    },
  },
  setup(){
    const alphabet = ["A","B","C","Ç","D","E","F","G","H","I","İ","J","K","L","M","N","O","Ö","P","Q","R","S","Ş","T","U","Ü","V","W","X","Y","Z"];

    return {
        alphabet,
    };
  }
});
