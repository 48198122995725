import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlphabeticKeyboardHeader = _resolveComponent("AlphabeticKeyboardHeader")!
  const _component_List = _resolveComponent("List", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlphabeticKeyboardHeader, { onAlpSelected: _ctx.alphselected }, null, 8, ["onAlpSelected"]),
    _createVNode(_component_List, {
      "widget-classes": "mb-5 mb-xl-8",
      tableData: _ctx.myList.data
    }, null, 8, ["tableData"])
  ], 64))
}